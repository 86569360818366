<script>
import { PolarArea } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  extends: PolarArea,
    props: {
        data: Array,
        labels: Array
    },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: this.data,
    }, {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        custom: CustomTooltips,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
          labelColor: function (tooltipItem, chart) {
            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
          }
        }
      }
    })
  }
}
</script>
