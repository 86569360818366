<script>
import { Bar } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities.js'

export default {
  components: {
    hexToRgba,
    CustomTooltips
  },
  props: {
    avg: Array,
    max: Array,
    min: Array,
    labels: Array,
    data: Array,
    title: String,
  },

  extends: Bar,

  mounted () {
    var datasets;
    var scales;
    if(this.min){
      datasets = [
          {
              label: 'min',
              backgroundColor: '#288fb4',
              borderColor:'rgb(251,253,255)',
              borderWidth: 0.3,
              data: this.avg
          },
          {
              label: 'Avg',
              backgroundColor: '#1d556f',
              borderColor:'rgb(251,253,255)',
              borderWidth: 0.1,
              data: this.avg
          },
          {
              label: 'max',
              backgroundColor: '#efddb2',
              borderColor:'rgb(251,253,255)',
              borderWidth: 0.2,
              data: this.avg
          }
      ];
      scales = {
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Milliseconds'
          }
        }],
        xAxes: [{
          stacked: true,

        }]
      }
    }
    else{
      datasets = [{
          label: this.title,
          backgroundColor: '#f87979',
          data: this.data
      }];
      scales = {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Occurrences'
          }
        }],
      }
    }
    this.renderChart(
      {
        labels: this.labels,
        datasets: datasets
      },
      {
        scales: scales,
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    )
  }
}
</script>
