<script>
import { Line } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities.js'

export default {
  components: {
    hexToRgba,
    CustomTooltips
  },
  props: {
    data: Array,
    labels: Array,
    title: String,
  },

  extends: Line,
  mounted () {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.title,
            backgroundColor: hexToRgba('#9198e5'),
            data: this.data
          },
        ]
      },
      {
        scales: {
          yAxes: [{
            ticks: {
              callback: (value) => {
                if(this.title === 'Camera Up vs Down'){
                  if(value===1){
                    return "Up";
                  }
                  if(value===0){
                    return "Down";
                  }
                  else{return "";}
                }
                else{
                  return value;
                }

              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    )
  }
}
</script>
