<script>
import {Pie} from 'vue-chartjs'

export default {
    extends: Pie,
    props: {
        data: Array,
        total: Number,
        labels: Array,
        colors: Array,

    },
    data() {
        return {
            oldData: []
        }
    },
    mounted() {
        this.chartUpdate()
    },
    methods: {
        chartUpdate() {
            if (this.oldData.length !== this.data.length || !this.oldData.every((_, i) => _ === this.data[i])) {
                this.oldData = this.data
                this.renderChart({
                    labels: this.labels.map((e, i) => {
                        return this.data[i] + " " + e;
                    }),
                    datasets: [
                        {
                            backgroundColor: this.colors,
                            data: this.data
                        }
                    ]
                }, {events: [], responsive: true, maintainAspectRatio: false,})
            }
        }
    },
    watch: {
        data() {
            this.chartUpdate()
        }
    }
}
</script>
