<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,

    props: {
        data: Array,
        labels: Array
    },

  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          backgroundColor: [
            '#34744b',
            '#dd1b16',

          ],
          data: this.data,
        }
      ]
    }, {responsive: true, maintainAspectRatio: true})
  }
}
</script>
